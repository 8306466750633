import React, { useMemo, useState } from 'react';
import MainLayout from '../layouts/main';
import {
  Typography,
  TextField,
  InputAdornment,
  Card,
  Divider
} from '@material-ui/core';
import styled from '../styled';
import PageHead from '../components/PageHead';
import { SEO } from '../components/SEO';
import { Link, graphql } from 'gatsby';
import { Integration } from '../types/Integration';
import { Label } from '../components/Label';
import { Search as SearchIcon } from 'react-feather';
import { CtaButton } from '../components/CtaButton';
import { AlertBox } from '../components/AlertBox';
import { ContactForm } from '../components/ContactForm';

const SearchWrapper = styled('div')`
  margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  text-align: center;
`;

const MissingIntegrationWrapper = styled('div')`
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px;
  max-width: 600px;
  margin: 0 auto 48px;
`;

const LogosWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing(2)}px;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
  min-height: 400px;

  img {
    max-width: 200px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  h3 {
    font-weight: normal;
    font-size: inherit;
  }
`;

const LogoContainer = styled(Card)`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  width: 230px;
  text-align: center;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.03);
  border: 1px solid #f9f9f9;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  transform: scale(1);
  transition: transform 0.2s linear;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.2s linear;
    border: 1px solid #eee;
  }
`;

const Logo = styled('img')`
  height: 32px;
  max-width: 80%;
  display: block;
  margin: ${(p) => p.theme.spacing(5)}px auto ${(p) => p.theme.spacing(3)}px;

  @media (max-width: 1200px) {
    height: 35px;
    margin: 6px;
  }
`;

const Integrations = styled('div')`
  text-align: center;
  margin: 0 auto;
  color: #333;
  width: 90%;
  padding: 24px;
`;

type PageQueryData = {
  allSanityAffiliateNetwork: {
    edges: {
      node: Integration;
    }[];
  };
};

const PRIORITIZED_INTEGRATIONS = [
  'ads-by-money',
  'avantlink',
  'awin',
  'cj-affiliate',
  'commission-factory',
  'everflow',
  'flexoffers',
  'howl',
  'partnerize',
  'partnerstack',
  'pepperjam',
  'post-affiliate-pro',
  'rakuten',
  'red-ventures',
  'refersion',
  'shareasale',
  'skimlinks',
  'tradedouber',
  'tune'
];

export default ({
  data: {
    allSanityAffiliateNetwork: { edges }
  }
}: {
  data: PageQueryData;
}) => {
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const integrations = edges.map((e) => e.node);

  const visibleIntegrations = useMemo(() => {
    return integrations
      .filter((i) => {
        if (query === '') {
          return PRIORITIZED_INTEGRATIONS.indexOf(i.slug.current) !== -1;
        }
        return i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      })
      .slice(0, PRIORITIZED_INTEGRATIONS.length);
  }, [integrations, PRIORITIZED_INTEGRATIONS, query]);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };
    xhr.send(data);
  };

  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/integrations/"
          title="Integrations with Affiliate Networks"
          description="Affilimate's integrations with top affiliate networks, subnetworks, direct affiliate programs, and affiliate aggregators."
        />
        <PageHead style={{ marginBottom: 0 }}>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '600px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Integrations
          </Typography>
          <Typography
            paragraph
            style={{ maxWidth: '600px', padding: '24px', margin: '0 auto' }}
          >
            Affilimate integrates with <strong>100+</strong> of the most popular
            affiliate platforms, networks, subnetworks and aggregators, SaaS
            software, and data sources. Here are a few popular examples:
          </Typography>
        </PageHead>
        <Integrations>
          <div style={{ position: 'relative', margin: '24px auto' }}>
            <LogosWrapper>
              {visibleIntegrations.map((i) => (
                <LogoContainer key={i.slug.current}>
                  {i.status === 'comingSoon' && <Label>Coming Soon</Label>}
                  <Logo src={i.logo.image.src} alt={`${i.name} Integration`} />
                  <Typography
                    component="h4"
                    variant="body2"
                    color="textSecondary"
                    paragraph
                  >
                    {i.name}
                  </Typography>
                </LogoContainer>
              ))}
            </LogosWrapper>
          </div>
        </Integrations>
        <MissingIntegrationWrapper>
          <Typography
            variant="h6"
            component="p"
            style={{ fontWeight: 'bold' }}
            paragraph
          >
            Looking for a specific integration?
          </Typography>
          <Typography variant="body1" paragraph>
            Affilimate integrations with over 100 data sources. Get in touch
            with us about the integration you want to know about, and we'll let
            you know what support looks like.
          </Typography>
          <Divider />
          <br />
          <ContactForm />
        </MissingIntegrationWrapper>
      </div>
    </MainLayout>
  );
};

export const pageQuery = graphql`
  query MyQuery {
    allSanityAffiliateNetwork(
      filter: { status: { in: ["supported", "comingSoon"] } }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          name
          status
          slug {
            current
          }
          logo {
            image {
              src
            }
          }
        }
      }
    }
  }
`;
