import styled from '../styled';

export const Label = styled('span')`
  background-color: #eee;
  color: #999;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: ${(p) => p.theme.shape.borderRadius / 2}px;
  font-size: 11px;
  padding: 4px 9px;
  position: absolute;
  top: 12px;
  right: 12px;
`;
